import React, { useState } from 'react';
//import Web3 from 'web3';

import logo from "../images/logo.png";
import nephele from "../images/nephele.png";
import db from "../swap_sin_db.json";
import db2 from "../swap_sin_db2.json";

const Card = () => {
    const [account, setAccount] = useState(null);
    const [nephTokens, setNephTokens] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const [sinTokens, setSinTokens] = useState(0);
    const [discordId, setDiscordId] = useState(0);
    const [sinCustom, setSinCustom] = useState(0);
    const [metaMaskInstalled, setMetaMaskInstalled] = useState(true);
    const nephCoins = parseFloat(nephTokens);



    const cleanNumericString = (value) => {
        // Supprimer les espaces de la chaîne numérique pour assurer une conversion correcte
        return value.replace(/\s/g, '');
    };

    const connectMetaMask = async () => {
        if (window.ethereum) {
            try {
                const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                const userAddress = accounts[0]?.toLowerCase(); // Utilisation de ?. pour éviter l'erreur si accounts[0] est null ou undefined
                setAccount(userAddress);

                if (!userAddress) {
                    console.error('User address not available.');
                    return;
                }

                // Recherche de l'utilisateur dans les deux bases de données
                const user1 = db.find(u => (u["NEPH Address"]?.toLowerCase() === userAddress) || (u["SIN Address Customized"]?.toLowerCase() === userAddress));
                const user2 = db2.find(u => (u["NEPH Address"]?.toLowerCase() === userAddress) || (u["SIN Address Customized"]?.toLowerCase() === userAddress));

                let totalNephTokens = 0;

                if (user1) {
                    // Convertir et ajouter les NEPH Tokens
                    totalNephTokens += parseFloat(cleanNumericString(user1["Amount NEPH Coins"]).replace(',', '.')) || 0;
                    setDiscordId(user1["Discord ID"] || null);
                    setSinCustom(user1["SIN Address Customized"] || null);
                }

                if (user2) {
                    // Convertir et ajouter les NEPH Tokens
                    totalNephTokens += parseFloat(cleanNumericString(user2["Amount NEPH Coins"]).replace(',', '.')) || 0;
                    // Mettre à jour Discord ID et SIN Address Customized si non définis
                    if (!discordId) setDiscordId(user2["Discord ID"] || null);
                    if (!sinCustom) setSinCustom(user2["SIN Address Customized"] || null);
                }

                setNephTokens(totalNephTokens);
                setSinTokens(user1 ? parseFloat(user1["Amount SIN Coins"]?.replace(',', '.')) || 0 : 0); // Utilise SIN Coins de user1 si présent, sinon 0

            } catch (error) {
                console.error('User rejected the request or an error occurred.');
            }
        } else {
            setMetaMaskInstalled(false);
            console.error('MetaMask is not installed.');
        }
    };



    return (
        <div>
            <div className='space'>
                <div className='card'>
                    <div className='bg'>
                        <img className='img2' src={logo} alt='nephelelogo' />
                        <img className='img' src={nephele} alt="nepheletext" />
                        {!metaMaskInstalled && (
                            <p className='error1' style={{ color: 'red' }}>MetaMask is not installed. Please install MetaMask and try again.</p>
                        )}
                        {account ? (
                            <div id="userDetails">
                                <div className='results1-container'>
                                    <div className='results1'>
                                        <div className='space1'>
                                            <div className='space'><strong className='strong'>Address : </strong></div>
                                            <span className="account">{account}</span>
                                        </div>
                                    </div>
                                    {/* <div className='results1'><strong className='strong'>Sin Address </strong> <span id="userAddress">{sinCustom}</span></div> */}
                                </div>
                                <div className='results-container'>
                                    {/* <div className='results'><strong>Discord Id : </strong> <span id="userDiscord">{discordId}</span></div> */}
                                    <div className='results'><strong>NEPH Coins : </strong> <span id="nephTokens">{nephCoins}</span></div>
                                    {/* <div className='results'><strong>SIN Coins : </strong> <span id="sinTokens">{sinTokens}</span></div> */}
                                </div>
                            </div>
                        ) : (
                            <div>
                                <button className='btn' onClick={connectMetaMask}>Connect MetaMask</button>
                                <p className='advise'>Take a look at your NEPH tokens !</p>
                                <p className='advise2'>You need to have the Metamask extension</p>
                            </div>
                        )}
                    </div>
                    <div className='blob'></div>
                </div>
            </div>
        </div>
    );
};

export default Card;
